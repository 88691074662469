import { defineRouting } from 'next-intl/routing';
import { createNavigation } from 'next-intl/navigation';
import { defaultLocale as defaultL } from '~/const/locale';

export const defaultLocale = defaultL;

export const locales = ['de', 'en', 'es', 'fr', 'it', 'nl', 'pt'] as const;

export const acceptLanguages = [
  'de_DE',
  'en_GB',
  'es_ES',
  'fr_FR',
  'it_IT',
  'nl_BE',
  'pt_PT',
] as const;

export const routing = defineRouting({
  locales,
  defaultLocale,
  localePrefix: 'as-needed',
});

// Lightweight wrappers around Next.js' navigation APIs
// that will consider the routing configuration
export const { Link, redirect, usePathname, useRouter }
  = createNavigation(routing);
